// import logo from "./logo.svg";
import "./App.css";
// import Temp from "./Components/Temp";

function App() {
  return (
    <div className="App">
      {/* <Temp /> */}
      <iframe title="Biosite" src="https://bio.site/alice"></iframe>
    </div>
  );
}

export default App;
